import React from "react";

const MailIcon = ({ width = "16", height = "16", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 0H1.5C0.675 0 0.00750017 0.66105 0.00750017 1.469L0 10.283C0 11.0909 0.675 11.752 1.5 11.752H13.5C14.325 11.752 15 11.0909 15 10.283V1.469C15 0.66105 14.325 0 13.5 0ZM1.50001 2.93796L7.5 6.6105L13.5 2.93796V10.283H1.50001V2.93796ZM1.50001 1.46901L7.5 5.1415L13.5 1.46901H1.50001Z"
        fill={fill}
      />
    </svg>
  );
};

export default MailIcon;
