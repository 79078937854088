import React, { useEffect } from "react";
import HeroSection from "../containers/homepage/HeroSection/HeroSection";
import ContactSection from "../containers/homepage/ContactSection/ContactSection";
import BioSection from "../containers/homepage/BioSection/BioSection";
import ProjectsSection from "../containers/homepage/projectsSection/ProjectsSection";
import NewsSection from "../containers/homepage/NewsSection/NewsSection";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeroSection />
      <BioSection />
      <NewsSection />
      <ProjectsSection />
      <ContactSection />
    </>
  );
};

export default Homepage;
