import React, { useEffect, useRef } from "react";
import styles from "./GoTop.module.scss";
import ArrowUpIcon from "../../components/svgs/ArrowUpIcon";

const GoTop = () => {
  const goTopRef = useRef(null);

  const handleGoTopClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (goTopRef?.current) {
        if (window.scrollY < 300) {
          goTopRef.current.style.display = "none";
        } else {
          goTopRef.current.style.display = "flex";
        }
      }
    });
  }, []);

  return (
    <div
      id="goTop"
      ref={goTopRef}
      className={styles.goTop}
      onClick={handleGoTopClick}
    >
      <ArrowUpIcon width="24" height="24" />
    </div>
  );
};

export default GoTop;
