import React, { useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";

const UploadFile = ({ formData, setFormData, errors }) => {
  const [dragOver, setDragOver] = useState(false);
  const fileRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile.type.split("/")[0] !== "image") {
      return alert("Please upload only an image file!");
    }

    setFormData({ ...formData, file: selectedFile });
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = () => setDragOver(false);

  const onDrop = (e) => {
    e.preventDefault();

    setDragOver(false);

    const selectedFile = e?.dataTransfer?.files[0];

    if (selectedFile.type.split("/")[0] !== "image") {
      return alert("Please upload only an image file!");
    }

    setFormData({ ...formData, file: selectedFile });
  };

  const handleRemoveImageClick = (e) => {
    e.stopPropagation();
    fileRef.current.value = "";
    setFormData({ ...formData, file: null });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: dragOver
          ? "3px solid #1976d2"
          : errors.file
          ? "1px solid #d32f2f"
          : "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "10px",
        padding: "24px",
        cursor: formData.file ? "default" : "pointer",
        "&:hover": {
          transition: "0.3s",
          borderColor: "#000000",
        },
      }}
      onClick={() => !formData.file && fileRef.current.click()}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <input
        ref={fileRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {!formData.file ? (
        <>
          <Box>
            <CloudUploadIcon color="primary" fontSize="large" />
          </Box>
          <Typography sx={{ color: "grey", margin: "10px 0px 20px" }}>
            Drag and drop an image here, or click to select an image
          </Typography>
          <Button variant="contained" color="primary">
            Upload image
          </Button>
        </>
      ) : (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <img
            style={{
              borderRadius: "20px",
              maxWidth: "200px",
              maxHeight: "200px",
              objectFit: "cover",
            }}
            src={URL.createObjectURL(formData.file)}
            alt={formData.file.name}
          />
          <CancelIcon
            color="error"
            fontSize="large"
            sx={{
              position: "absolute",
              top: "-17px",
              right: "-17px",
              cursor: "pointer",
            }}
            onClick={handleRemoveImageClick}
          />
        </Box>
      )}
    </Box>
  );
};

export default UploadFile;
