import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import InstagramIcon from "../svgs/InstagramIcon";
import MailIcon from "../svgs/MailIcon";
import appLogo from "../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMenuClick = (id) => {
    if (location.pathname === "/") {
      const el = document.getElementById(id);
      const header = document.querySelector("header");
      if (el && header) {
        const y =
          el.getBoundingClientRect().top +
          window.pageYOffset -
          header.offsetHeight;
        window.scrollTo({ top: y, behavior: "smooth" });
        setShowMobileMenu(false);
      }
    } else {
      navigate("/");
      setShowMobileMenu(false);
      setTimeout(() => {
        const el = document.getElementById(id);
        const header = document.querySelector("header");
        if (el && header) {
          const y =
            el.getBoundingClientRect().top +
            window.pageYOffset -
            header.offsetHeight;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
      document.getElementById("goTop").style.display = "none";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMobileMenu]);

  return (
    <header className={styles.header}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.appLogo}>
            <Link to={"/"}>
              <img src={appLogo} alt="app-logo" />
            </Link>
          </div>
          <div className={styles.navigation}>
            <>
              <div className={styles.mobileMenu}>
                <div
                  className={styles.hamburgerIcon}
                  onClick={() => setShowMobileMenu(true)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div
                  className={`${styles.sidebarMenu} ${
                    showMobileMenu ? styles.opened : ""
                  }`}
                >
                  <div className={styles.closeSidebarMenu}>
                    <span onClick={() => setShowMobileMenu(false)}>
                      &times;
                    </span>
                  </div>
                  <ul>
                    <li
                      onClick={() => {
                        setShowMobileMenu(false);
                        navigate("/");
                      }}
                    >
                      Home
                    </li>
                    <li onClick={() => handleMenuClick("bio-section")}>Bio</li>
                    <li onClick={() => handleMenuClick("news-section")}>
                      News
                    </li>
                    <li onClick={() => handleMenuClick("projects-section")}>
                      Projects
                    </li>
                    <li onClick={() => handleMenuClick("contact-section")}>
                      Contact
                    </li>
                  </ul>
                  <div className={styles.social}>
                    <div
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/vika.matveeva_arm"
                        )
                      }
                    >
                      <InstagramIcon width="16" height="16" />
                    </div>
                    <div onClick={() => handleMenuClick("contact-section")}>
                      <MailIcon width="15" height="12" />
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li
                  onClick={() => {
                    setShowMobileMenu(false);
                    navigate("/");
                  }}
                >
                  Home
                </li>
                <li onClick={() => handleMenuClick("bio-section")}>Bio</li>
                <li onClick={() => handleMenuClick("news-section")}>News</li>
                <li onClick={() => handleMenuClick("projects-section")}>
                  Projects
                </li>
                <li onClick={() => handleMenuClick("contact-section")}>
                  Contact
                </li>
              </ul>
            </>
            <div className={styles.social}>
              <div
                onClick={() =>
                  window.open("https://www.instagram.com/vika.matveeva_arm")
                }
              >
                <InstagramIcon width="16" height="16" />
              </div>
              <div onClick={() => handleMenuClick("contact-section")}>
                <MailIcon width="15" height="12" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
