import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import styles from "../containers/homepage/projectsSection/ProjectsSection.module.scss";
import axios from "axios";

const ProjectDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isImagesLoading, setIsImagesLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [projectImages, setProjectImages] = useState([]);

  const getProjectById = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/users/projects/${Number(
          params?.id
        )}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setProject(res.data);
        getProjectImages(res.data.image);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err.message);
      });
  };

  const getProjectImages = async (image) => {
    setIsImagesLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/users/${Number(
          params?.id
        )}/images`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      )
      .then((res) => {
        res.data.unshift({ images: image });
        setProjectImages(res.data.map((i) => i.images));
        setIsImagesLoading(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!project && !isLoading) {
    return <Navigate to={"/"} />;
  }

  return !isLoading ? (
    <div className={styles.projectsSection}>
      <div className="container">
        <div className={styles.projectTitle}>{project.title}</div>
      </div>
      {!isImagesLoading ? (
        <div className={styles.projectsGrid}>
          {projectImages.map((image, index) => {
            return (
              <div
                style={{ cursor: "pointer", aspectRatio: 1 }}
                key={index}
                onClick={() => navigate(`/project/${project.id}/images`)}
              >
                <img
                  src={"data:image/jpg;base64," + image}
                  alt={project.title}
                  key={index}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            color: "#ffffff",
            fontFamily: "Inter",
            marginTop: "20px",
          }}
        >
          Loading images...
        </div>
      )}
      <div className="container">
        <div className={styles.projectDescription}>
          <pre>{project.description}</pre>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        textAlign: "center",
        color: "#ffffff",
        fontFamily: "Inter",
        marginTop: "20px",
      }}
    >
      Loading...
    </div>
  );
};

export default ProjectDetails;
