import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const Bio = ({ enteredPassword }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fullname, setFullname] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({
    fullname: null,
    description: null,
  });

  const handleSaveClick = async () => {
    if (!fullname || !description) {
      setErrors({
        fullname: !fullname ? "Full Name can't be empty." : null,
        description: !description ? "Description can't be empty." : null,
      });
    } else {
      setErrors({
        fullname: null,
        description: null,
      });
      setIsLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/admin/bio`,
          {
            enteredPassword,
            bioDTO: {
              fullName: fullname,
              bioDescription: description,
            },
          },
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const fetchBio = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/bio`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      })
      .then((res) => {
        setIsLoading(false);
        setFullname(res.data[0].fullName);
        setDescription(res.data[0].bioDescription);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchBio();
  }, []);

  return (
    <Box>
      <Typography variant="h5" mb={4}>
        Add your bio
      </Typography>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <TextField
          type="text"
          label="Full Name"
          variant="outlined"
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
          error={!!errors.fullname}
          helperText={errors.fullname}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <TextField
          type="text"
          label="Description"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={4}
          error={!!errors.description}
          helperText={errors.description}
        />
      </FormControl>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" onClick={handleSaveClick}>
          Save
        </Button>
      </Box>
      <Backdrop sx={{ color: "#fff", zIndex: 1301 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Bio;
