import React, { useState } from "react";
import styles from "./ContactSection.module.scss";
import axios from "axios";
import Title from "../../../components/title/Title";

const ContactSection = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const isValidEmail = (str) => {
    return String(str)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSendClick = () => {
    if (!email || !message) {
      alert("Please fill in both fields.");
    } else if (!isValidEmail(email)) {
      alert("Email address is not valid.");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/users/contact/sendMessage`,
          { email, message },
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setEmail("");
            setMessage("");
            alert("Email has been successfully sent.");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  return (
    <div id="contact-section" className={styles.contactSection}>
      <Title text="Contact" />
      <div className={styles.contactSectionWrapper}>
        <div className={styles.overlay} />
        <div className={styles.contactForm}>
          <div className={styles.formControl}>
            <label htmlFor="email">YOUR E-MAIL ADDRESS</label>
            <input
              id="email"
              type="email"
              placeholder="E-Mail ..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.formControl}>
            <label htmlFor="message">MESSAGE</label>
            <textarea
              id="message"
              placeholder="Type your message ..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className={styles.sendBtn}>
            <button onClick={handleSendClick}>SEND</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
