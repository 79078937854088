import React from "react";
import styles from "./ProjectCard.module.scss";
// import CopyrightIcon from "../svgs/CopyrightIcon";
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.projectCard}
      key={project.id}
      onClick={() => navigate(`/project/${project.id}`)}
    >
      <div className={styles.projectImage}>
        <img src={"data:image/jpg;base64," + project.image} alt="project" />
        {/* <p>
          <CopyrightIcon width="16" height="16" />
          {project.photoBy.name}
          <span>{project.photoBy.company}</span>
        </p> */}
      </div>
      <div className={styles.projectFooter}>{project.title}</div>
    </div>
  );
};

export default ProjectCard;
