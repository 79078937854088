import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AdminPanel = () => {
  const navigate = useNavigate();
  const [enteredPassword, setEnteredPassword] = useState("");

  useEffect(() => {
    const pass = prompt("Please enter the password");
    if (pass !== "qwerty") {
      alert("Password is incorrect.");
      navigate("/");
    } else {
      setEnteredPassword(pass);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!enteredPassword) return null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Sidebar enteredPassword={enteredPassword} />
    </LocalizationProvider>
  );
};

export default AdminPanel;
