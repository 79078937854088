import React from "react";
import styles from "./Footer.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import appLogo from "../../assets/images/logo.png";
import InstagramIcon from "../svgs/InstagramIcon";
import MailIcon from "../svgs/MailIcon";
import CopyrightIcon from "../svgs/CopyrightIcon";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleMenuClick = (id) => {
    const el = document.getElementById(id);
    const header = document.querySelector("header");
    if (el && header) {
      const y =
        el.getBoundingClientRect().top +
        window.pageYOffset -
        header.offsetHeight;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.appLogo}>
        <Link to={"/"}>
          <img src={appLogo} alt="app-logo" />
        </Link>
      </div>
      {location.pathname === "/" ? (
        <ul className={styles.navigation}>
          <li
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </li>
          <li onClick={() => handleMenuClick("bio-section")}>Bio</li>
          <li onClick={() => handleMenuClick("news-section")}>News</li>
          <li onClick={() => handleMenuClick("projects-section")}>Projects</li>
          <li onClick={() => handleMenuClick("contact-section")}>Contact</li>
        </ul>
      ) : null}
      <div className={styles.social}>
        <div
          onClick={() =>
            window.open("https://www.instagram.com/vika.matveeva_arm")
          }
        >
          <InstagramIcon width="16" height="16" />
        </div>
        <div onClick={() => handleMenuClick("contact-section")}>
          <MailIcon width="15" height="12" />
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.copyright}>
        <CopyrightIcon width="16" height="16" />
        <div className={styles.copyrightText}>
          {`${new Date().getFullYear()} All Rights Reserved`}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
