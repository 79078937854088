import React, { useEffect, useRef } from "react";
import Header from "../components/header/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/footer/Footer";
import GoTop from "../components/goTop/GoTop";

const Layout = () => {
  const location = useLocation();
  const appContentRef = useRef(null);

  useEffect(() => {
    const header = document.querySelector("header");
    if (header) {
      appContentRef.current.style.marginTop = header.offsetHeight + "px";
    }
  }, []);

  return (
    <>
      {location.pathname !== "/admin" ? <Header /> : null}
      <div ref={appContentRef} className="app-content">
        <Outlet />
        {location.pathname !== "/admin" ? <GoTop /> : null}
      </div>
      {location.pathname !== "/admin" ? <Footer /> : null}
    </>
  );
};

export default Layout;
