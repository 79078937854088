import React, { useEffect, useState } from "react";
import styles from "../containers/homepage/projectsSection/ProjectsSection.module.scss";
import ProjectCard from "../components/projectCard/ProjectCard";
import axios from "axios";

const Projects = () => {
  const [projectsData, setProjectsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProjects = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/projects`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      })
      .then((res) => {
        setIsLoading(false);
        setProjectsData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err.message);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProjects();
  }, []);

  return (
    <div className={styles.projectsSection}>
      <div className={styles.projectTitle}>Projects</div>
      <div className="container">
        {!isLoading ? (
          <div className={styles.projectsGrid}>
            {projectsData.map((project) => {
              return <ProjectCard key={project.id} project={project} />;
            })}
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontFamily: "Inter",
            }}
          >
            Loading...
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
