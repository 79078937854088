import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadFile from "../../../../components/uploadFile/UploadFile";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const Projects = ({ enteredPassword }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    desc: "",
    file: null,
  });
  const [errors, setErrors] = useState({
    title: null,
    desc: null,
    file: null,
  });

  const handleAddPhotoClick = async () => {
    if (!formData.file) {
      setErrors({
        ...formData,
        file: !formData.file ? "Ptoject image can't be empty." : null,
      });
    } else {
      setErrors({
        ...formData,
        file: null,
      });
      setIsLoading(true);
      let fd = new FormData();
      fd.append("file", formData.file);
      fd.append("enteredPassword", enteredPassword);
      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/admin/update/${editingProject.id}/add-photo`,
          fd,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          handleCloseDialog();
        })
        .catch((err) => {
          setIsLoading(false);
          alert(err.message);
        });
    }
  };

  const handleSaveClick = async () => {
    if (!formData.title || !formData.desc || !formData.file) {
      setErrors({
        title: !formData.title ? "Title can't be empty." : null,
        desc: !formData.desc ? "Description can't be empty." : null,
        file: !formData.file ? "Ptoject image can't be empty." : null,
      });
    } else {
      setErrors({
        title: null,
        desc: null,
        file: null,
      });
      setIsLoading(true);
      let fd = new FormData();
      fd.append("title", formData.title);
      fd.append("desc", formData.desc);
      fd.append("file", formData.file);
      fd.append("enteredPassword", enteredPassword);
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/admin/projects`, fd, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        })
        .then((res) => {
          setIsLoading(false);
          handleCloseDialog();
          fetchProjects();
        })
        .catch((err) => {
          setIsLoading(false);
          alert(err.message);
        });
    }
  };

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = (value) => {
    setShowDialog(false);
    setEditingProject(null);
    setFormData({
      title: "",
      desc: "",
      file: null,
    });
  };

  const handleAttPhotosToProjectClick = async (item) => {
    handleOpenDialog();
    setEditingProject(item);
  };

  const handleDeleteProjectClick = async (id) => {
    setIsLoading(true);
    const projectImages = await getProjectImages(id);
    Promise.all(
      projectImages.map(async (image) => {
        await deleteProjectImages(image.id);
      })
    );
    const fd = new FormData();
    fd.append("enteredPassword", enteredPassword);
    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/admin/delete/projects/${id}`,
        {
          data: fd,
        },
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        handleCloseDialog();
        fetchProjects();
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err.message);
      });
  };

  const getProjectImages = async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users/${Number(id)}/images`,
      {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      }
    );
    return response.data;
  };

  const deleteProjectImages = async (id) => {
    const fd = new FormData();
    fd.append("enteredPassword", enteredPassword);
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/admin/delete/image/${Number(id)}`,
      {
        data: fd,
      },
      {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      }
    );
    return response.data;
  };

  const fetchProjects = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/projects`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      })
      .then((res) => {
        setProjectsData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err.message);
      });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="outlined"
          color="success"
          startIcon={<AddIcon />}
          onClick={handleOpenDialog}
        >
          Add a Project
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Main Image</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          {projectsData.length ? (
            <TableBody>
              {projectsData.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Avatar
                      src={"data:image/jpg;base64," + item.image}
                      alt={item.title}
                      variant="square"
                    />
                  </TableCell>
                  <TableCell align="center">{item.title}</TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Tooltip title="Add photos">
                        <IconButton
                          onClick={() => handleAttPhotosToProjectClick(item)}
                        >
                          <AddAPhotoIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleDeleteProjectClick(item.id)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography fontStyle={"italic"} textAlign={"center"}>
                    No records found.
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Dialog onClose={handleCloseDialog} open={showDialog}>
        <DialogTitle>
          {editingProject
            ? `Add photo to ${editingProject.title}`
            : "Add a Project"}
        </DialogTitle>
        {!editingProject ? (
          <DialogContent sx={{ paddingTop: "20px!important" }}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                type="text"
                label="Title"
                variant="outlined"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                error={!!errors.title}
                helperText={errors.title}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                type="text"
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                value={formData.desc}
                onChange={(e) =>
                  setFormData({ ...formData, desc: e.target.value })
                }
                error={!!errors.desc}
                helperText={errors.desc}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <UploadFile
                formData={formData}
                setFormData={setFormData}
                errors={errors}
              />
              {errors.file ? (
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "#d32f2f",
                    marginTop: "3px",
                    marginLeft: "14px",
                  }}
                >
                  {errors.file}
                </Typography>
              ) : null}
            </FormControl>
          </DialogContent>
        ) : (
          <DialogContent>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <UploadFile
                formData={formData}
                setFormData={setFormData}
                errors={errors}
              />
              {errors.file ? (
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "#d32f2f",
                    marginTop: "3px",
                    marginLeft: "14px",
                  }}
                >
                  {errors.file}
                </Typography>
              ) : null}
            </FormControl>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseDialog}
          >
            Close
          </Button>
          {!editingProject ? (
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveClick}
              autoFocus
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={handleAddPhotoClick}
              autoFocus
            >
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: "#fff", zIndex: 1301 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Projects;
