import React from "react";

const InstagramIcon = ({ width = "16", height = "16", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4375 1.53779L13.4362 1.5378L11.2272 1.54354L10.7273 1.54483L10.7285 2.04471L10.7344 4.53512L10.7356 5.03508L11.2355 5.03395L13.7726 5.02821L14.2715 5.02708V4.52821V2.35914V2.0378V1.5378H13.7715L13.4375 1.53779ZM5.4596 5.88316L5.45959 5.88315L5.45774 5.88568C5.08865 6.39037 4.86328 7.01478 4.86328 7.69C4.86328 9.39544 6.26526 10.778 7.99414 10.778C9.7201 10.778 11.1367 9.39832 11.1367 7.69C11.1367 7.01872 10.9196 6.39266 10.5406 5.88348C9.96906 5.10614 9.04271 4.60204 8 4.60204C6.95475 4.60204 6.02953 5.11399 5.4596 5.88316ZM1.96484 5.68083H1.46484V6.18083V12.1946C1.46484 13.2552 2.34313 14.1004 3.40039 14.1004H12.5996C13.6569 14.1004 14.5352 13.2552 14.5352 12.1946V6.18083V5.68083H14.0352H11.7969H11.0473L11.3352 6.37288C11.5049 6.78065 11.6016 7.22628 11.6016 7.69C11.6016 9.61904 9.99547 11.2068 8 11.2068C6.00453 11.2068 4.39844 9.61904 4.39844 7.69C4.39844 7.22628 4.49514 6.78065 4.66477 6.37288L4.95267 5.68083H4.20312H1.96484ZM12.5996 14.535H3.40039C2.06703 14.535 1 13.4752 1 12.1946V6.18083V3.17971C1 1.8991 2.06703 0.839264 3.40039 0.839264H12.5996C13.933 0.839264 15 1.8991 15 3.17971V6.18083V12.1946C15 13.4752 13.933 14.535 12.5996 14.535Z"
        stroke={fill}
      />
    </svg>
  );
};

export default InstagramIcon;
