import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import styles from "../containers/homepage/projectsSection/ProjectsSection.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, FreeMode, Navigation, Thumbs } from "swiper/modules";
import axios from "axios";

const ProjectImages = () => {
  const params = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isImagesLoading, setIsImagesLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [projectImages, setProjectImages] = useState([]);

  const getProjectById = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/users/projects/${Number(
          params?.id
        )}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setProject(res.data);
        getProjectImages(res.data.image);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err.message);
      });
  };

  const getProjectImages = async (image) => {
    setIsImagesLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/users/${Number(
          params?.id
        )}/images`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      )
      .then((res) => {
        res.data.unshift({ images: image });
        setProjectImages(res.data.map((i) => i.images));
        setIsImagesLoading(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!project && !isLoading) {
    return <Navigate to={"/"} />;
  }

  return !isLoading ? (
    <div className={styles.projectsSection}>
      <div className="container">
        <div className={styles.projectTitle}>{project.title}</div>
      </div>
      {!isImagesLoading ? (
        <div className={styles.projectSlider}>
          <Swiper
            effect={"fade"}
            loop={projectImages?.length > 3}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[EffectFade, FreeMode, Navigation, Thumbs]}
            className="project-details-swiper-main"
            breakpoints={{
              992: {
                loop: projectImages?.length > 4,
              },
            }}
          >
            {projectImages?.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    src={"data:image/jpg;base64," + image}
                    alt={project.title}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={projectImages?.length > 3}
            spaceBetween={10}
            slidesPerView={3}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="project-details-swiper-secondary"
            breakpoints={{
              992: {
                slidesPerView: 4,
                loop: projectImages?.length > 4,
              },
            }}
          >
            {projectImages?.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    src={"data:image/jpg;base64," + image}
                    alt={project.title}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            color: "#ffffff",
            fontFamily: "Inter",
            marginTop: "20px",
          }}
        >
          Loading images...
        </div>
      )}
    </div>
  ) : (
    <div
      style={{
        textAlign: "center",
        color: "#ffffff",
        fontFamily: "Inter",
        marginTop: "20px",
      }}
    >
      Loading...
    </div>
  );
};

export default ProjectImages;
