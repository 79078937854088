import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const News = ({ enteredPassword }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editingNews, setEditingNews] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    date: "",
    address: "",
    details: "",
    instagramLink: "",
  });
  const [errors, setErrors] = useState({
    title: null,
    date: null,
    address: null,
    details: null,
    instagramLink: null,
  });

  const handleSaveClick = async () => {
    if (
      !formData.title ||
      !formData.date ||
      !formData.address ||
      !formData.details ||
      !formData.instagramLink
    ) {
      setErrors({
        title: !formData.title ? "Title can't be empty" : null,
        date: !formData.date ? "Date can't be empty" : null,
        address: !formData.address ? "Address can't be empty" : null,
        details: !formData.details ? "Details can't be empty" : null,
        instagramLink: !formData.instagramLink
          ? "Instagram link can't be empty"
          : null,
      });
    } else {
      setErrors({
        title: null,
        date: null,
        address: null,
        details: null,
        instagramLink: null,
      });
      setIsLoading(true);
      const payload = {
        newsDTO: {
          title: formData.title,
          date: formData.date,
          address: formData.address,
          details: formData.details,
          instagramLink: formData.instagramLink,
        },
        enteredPassword,
      };
      if (editingNews) {
        await axios
          .put(
            `${process.env.REACT_APP_API_URL}/api/admin/update/news/${editingNews.id}`,
            payload,
            {
              headers: {
                "ngrok-skip-browser-warning": "true",
              },
            }
          )
          .then((res) => {
            setIsLoading(false);
            handleCloseDialog();
            fetchNews();
          })
          .catch((err) => {
            setIsLoading(false);
          });
      } else {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/api/admin/news`, payload, {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          })
          .then((res) => {
            setIsLoading(false);
            handleCloseDialog();
            fetchNews();
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    }
  };

  const fetchNews = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/news`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      })
      .then((res) => {
        setIsLoading(false);
        setNewsData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = (value) => {
    setShowDialog(false);
    setEditingNews(null);
    setFormData({
      title: "",
      date: null,
      address: "",
      details: "",
      instagramLink: "",
    });
  };

  const handleDeleteNewsClick = async (id) => {
    setIsLoading(true);
    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/admin/delete/news/${id}`,
        {
          data: { enteredPassword },
        },
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        handleCloseDialog();
        fetchNews();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleEditNewsClick = async (item) => {
    handleOpenDialog();
    setEditingNews(item);
    setFormData({
      title: item.title,
      date: item.date,
      address: item.address,
      details: item.details,
      instagramLink: item.instagramLink,
    });
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="outlined"
          color="success"
          startIcon={<AddIcon />}
          onClick={handleOpenDialog}
        >
          Add news
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Address</TableCell>
              <TableCell align="center">Details</TableCell>
              <TableCell align="center">Instagram</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          {newsData.length ? (
            <TableBody>
              {newsData.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item.title}
                  </TableCell>
                  <TableCell align="center">{item.date}</TableCell>
                  <TableCell align="center">{item.address}</TableCell>
                  <TableCell align="center">{item.details}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Go to instagram">
                      <IconButton
                        onClick={() => window.open(item.instagramLink)}
                      >
                        <InstagramIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditNewsClick(item)}>
                          <EditIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleDeleteNewsClick(item.id)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography fontStyle={"italic"} textAlign={"center"}>
                    No records found.
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Dialog onClose={handleCloseDialog} open={showDialog}>
        <DialogTitle>
          {editingNews ? `Updating ${editingNews.title}` : "Add news"}
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px!important" }}>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              type="text"
              label="Title"
              variant="outlined"
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              error={!!errors.title}
              helperText={errors.title}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              type="text"
              label="Date and Time"
              variant="outlined"
              value={formData.date}
              onChange={(e) =>
                setFormData({ ...formData, date: e.target.value })
              }
              error={!!errors.date}
              helperText={errors.date}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              type="text"
              label="Address"
              variant="outlined"
              value={formData.address}
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              error={!!errors.address}
              helperText={errors.address}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              type="text"
              label="Details"
              variant="outlined"
              multiline
              rows={4}
              value={formData.details}
              onChange={(e) =>
                setFormData({ ...formData, details: e.target.value })
              }
              error={!!errors.details}
              helperText={errors.details}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              type="text"
              label="Instagram Link"
              variant="outlined"
              value={formData.instagramLink}
              onChange={(e) =>
                setFormData({ ...formData, instagramLink: e.target.value })
              }
              error={!!errors.instagramLink}
              helperText={errors.instagramLink}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseDialog}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleSaveClick}
            autoFocus
          >
            {editingNews ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: "#fff", zIndex: 1301 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default News;
