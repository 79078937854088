import React, { useEffect, useState } from "react";
import styles from "./ProjectsSection.module.scss";
import Title from "../../../components/title/Title";
import { useNavigate } from "react-router-dom";
import ProjectCard from "../../../components/projectCard/ProjectCard";
import axios from "axios";

const ProjectsSection = () => {
  const navigate = useNavigate();
  const [projectsData, setProjectsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProjects = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/projects`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      })
      .then((res) => {
        setProjectsData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div id="projects-section" className={styles.projectsSection}>
      <Title text="Projects" />
      <div className="container">
        {!isLoading ? (
          <>
            <div className={styles.projectsGrid}>
              {projectsData.slice(0, 4).map((project) => {
                return <ProjectCard key={project.id} project={project} />;
              })}
            </div>
            {projectsData.length > 4 ? (
              <div className={styles.viewAll}>
                <button onClick={() => navigate("/projects")}>View All</button>
              </div>
            ) : null}
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "#ffffff",
              fontFamily: "Inter",
              marginTop: "20px",
            }}
          >
            Loading...
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsSection;
