import React, { useEffect, useState } from "react";
import styles from "./NewsSection.module.scss";
import Title from "../../../components/title/Title";
import ArrowUpIcon from "../../../components/svgs/ArrowUpIcon";
import InstagramIcon from "../../../components/svgs/InstagramIcon";
import axios from "axios";

const NewsSection = () => {
  const [newsData, setNewsData] = useState([]);

  const fetchNews = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/news`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      })
      .then((res) => {
        setNewsData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <div id="news-section" className={styles.newsSection}>
      <Title text="News" />
      <div className={styles.newsSectionWrapper}>
        <div className="container">
          {newsData.map((news, index) => {
            return (
              <div
                className={`${styles.newsFullWidth} ${
                  index % 2 === 0 ? styles.left : styles.right
                }`}
                key={news.id}
              >
                <div className={styles.news}>
                  <div className={styles.newsTitle}>{news.title}</div>
                  <div className={styles.newsDate}>
                    <span>{news.date}</span>
                  </div>
                  <div className={styles.newsAddress}>{news.address}</div>
                  <div className={styles.newsDetails}>
                    <div className={styles.details}>
                      <div className={styles.desc}>
                        <span>Details</span>
                        <ArrowUpIcon width="32" height="32" />
                        <div className={styles.showDetails}>
                          <pre>{news.details}</pre>
                        </div>
                      </div>
                      <div
                        className={styles.instargram}
                        title="View in Instagram"
                        onClick={() => window.open(news.instagramLink)}
                      >
                        <InstagramIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
