import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";
import heroImage1 from "../../../assets/images/hero/hero1.jpg";
import heroImage2 from "../../../assets/images/hero/hero2.jpg";
import heroImage3 from "../../../assets/images/hero/hero3.jpg";
import heroImage4 from "../../../assets/images/hero/hero4.jpg";
import heroImage5 from "../../../assets/images/hero/hero5.jpg";
import heroImage6 from "../../../assets/images/hero/hero6.jpg";
import styles from "./HeroSection.module.scss";

const HeroSection = () => {
  return (
    <div className={styles.heroSection}>
      <Swiper
        effect={"fade"}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
        className="hero-swiper"
      >
        <SwiperSlide>
          <img src={heroImage1} alt="hero1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={heroImage2} alt="hero2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={heroImage3} alt="hero3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={heroImage4} alt="hero4" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={heroImage5} alt="hero5" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={heroImage6} alt="hero6" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HeroSection;
