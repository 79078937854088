import React from "react";

const CopyrightIcon = ({ width = "16", height = "16", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99815 16C10.1204 16 12.1557 15.1569 13.6563 13.6563C15.1569 12.1556 16 10.1203 16 7.99813C15.9993 6.41605 15.5296 4.8697 14.6503 3.55449C13.771 2.23928 12.5215 1.21423 11.0598 0.608899C9.59813 0.0035647 7.9898 -0.154892 6.43808 0.153554C4.88636 0.462 3.4609 1.2235 2.34183 2.34183C0.84233 3.84232 0 5.87682 0 7.99813C0 10.1194 0.84233 12.1539 2.34183 13.6544C3.08218 14.4007 3.96349 14.9924 4.93452 15.395C5.90555 15.7977 6.94694 16.0033 7.99815 16ZM3.047 3.04699C3.69717 2.39676 4.46905 1.88096 5.31858 1.52905C6.1681 1.17715 7.07862 0.99602 7.99815 0.99602C8.91767 0.99602 9.82819 1.17715 10.6777 1.52905C11.5272 1.88096 12.2991 2.39676 12.9493 3.04699C13.9284 4.02621 14.5952 5.27378 14.8653 6.63194C15.1354 7.9901 14.9967 9.39785 14.4667 10.6772C13.9368 11.9565 13.0394 13.05 11.888 13.8193C10.7366 14.5886 9.38291 14.9992 7.99815 14.9992C6.61339 14.9992 5.25972 14.5886 4.10833 13.8193C2.95693 13.05 2.05952 11.9565 1.52956 10.6772C0.999601 9.39785 0.860904 7.9901 1.131 6.63194C1.40111 5.27378 2.06788 4.02621 3.047 3.04699Z"
        fill={fill}
      />
      <path
        d="M7.99827 12.5893C8.60124 12.59 9.19839 12.4715 9.75541 12.2406C10.3124 12.0097 10.8183 11.671 11.244 11.244C11.326 11.1483 11.3688 11.0253 11.3639 10.8994C11.359 10.7735 11.3069 10.6541 11.2178 10.5651C11.1287 10.476 11.0093 10.4238 10.8835 10.419C10.7576 10.4141 10.6345 10.4569 10.5389 10.5389C10.0365 11.0406 9.39668 11.3822 8.70024 11.5204C8.0038 11.6586 7.28202 11.5873 6.62613 11.3154C5.97023 11.0434 5.40967 10.5832 5.01529 9.99279C4.62091 9.40237 4.41042 8.70829 4.41042 7.99827C4.41042 7.28825 4.62091 6.59417 5.01529 6.00375C5.40967 5.41334 5.97023 4.95309 6.62613 4.68119C7.28202 4.40928 8.0038 4.33793 8.70024 4.47614C9.39668 4.61435 10.0365 4.95592 10.5389 5.45769C10.6345 5.53962 10.7576 5.58243 10.8835 5.57757C11.0093 5.57271 11.1287 5.52053 11.2178 5.43146C11.3069 5.3424 11.359 5.223 11.3639 5.09713C11.3688 4.97127 11.326 4.8482 11.244 4.75253C10.8179 4.3243 10.3113 3.98448 9.75346 3.75261C9.19559 3.52073 8.59741 3.40137 7.99327 3.40137C7.38914 3.40137 6.79095 3.52073 6.23309 3.75261C5.67522 3.98448 5.16866 4.3243 4.74252 4.75253C4.10009 5.39519 3.66283 6.21405 3.4861 7.1054C3.30938 7.99674 3.40113 8.92049 3.74976 9.75965C4.09838 10.5988 4.68819 11.3156 5.4445 11.8194C6.2008 12.3231 7.08958 12.5911 7.99827 12.5893Z"
        fill={fill}
      />
    </svg>
  );
};

export default CopyrightIcon;
