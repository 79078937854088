import React, { useEffect, useState } from "react";
import styles from "./BioSection.module.scss";
import Title from "../../../components/title/Title";
import bioImage from "../../../assets/images/bio-image.png";
import axios from "axios";

const BioSection = () => {
  const [fullName, setFullName] = useState("");
  const [description, setDescription] = useState("");

  const fetchBio = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/bio`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      })
      .then((res) => {
        setFullName(res.data[0]?.fullName);
        setDescription(res.data[0]?.bioDescription);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchBio();
  }, []);
  return (
    <div id="bio-section" className={styles.bioSection}>
      <Title text="Bio" />
      <div className={styles.bioSectionWrapper}>
        <div className={styles.bioImageAndProfessions}>
          <div className={styles.bioImage}>
            <img src={bioImage} alt="Bio" />
            <p>
              <span>Photo by</span>
              Chiara Capella
            </p>
          </div>
          <div className={styles.fullnameAndProfession}>
            <div className={styles.fullname}>{fullName}</div>
            <div className={styles.professions}>
              <p>
                <span>Choreographer</span>
              </p>
              <p>
                <span>Dance Teacher</span>
              </p>
              <p>
                <span>Dancer</span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.bioDescription}>
          <pre>{description}</pre>
        </div>
      </div>
    </div>
  );
};

export default BioSection;
